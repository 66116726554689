import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/Layout';
import PostCard from "../components/postCard"

import config from '../../config';
import Footer from '../components/Footer';
import SocialLinks from '../components/SocialLinks';
import Subscribe from '../components/Subscribe';
import Header from '../components/Header';

const IndexPage = ({ data }, location) => {
  const posts = data.allMarkdownRemark.edges;
  let postCounter = 0;

  return (
    <Layout>
      <Header />

      <header className="masthead">
        <div className="container d-flex h-100 align-items-center">
          <div className="mx-auto text-center">
            <h1 className="mx-auto my-0 text-uppercase">{config.heading}</h1>
            <h2 className="text-white-50 mx-auto mt-2 mb-5">
              {config.subHeading}
            </h2>
          </div>
        </div>
      </header>
      <section id="projects" className="projects-section bg-light">
        <div className="container">
        {posts.map(({ node }) => {
          postCounter++
          return (
            <PostCard
              key={node.fields.slug}
              count={postCounter}
              node={node}
              postClass={`post`}
            />
          )
        })}
      </div>
      </section>

      <Subscribe />

      <SocialLinks />
      <Footer />
    </Layout>
  );
};

const indexQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <IndexPage location={props.location} props data={data} {...props} />
    )}
  />
);
