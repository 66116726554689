import React from 'react';
import { Link } from 'gatsby';

export default props => (
  <Link
    to={props.node.fields.slug}
    className="row justify-content-center no-gutters"
  >
    <div className="col-lg-6">
      <img
        className="img-fluid"
        src={props.node.frontmatter.thumbnail.childImageSharp.fluid.src}
        alt=""
      />
    </div>
    <div
      className={`col-lg-6 ${props.count % 2 === 0 && `order-lg-first`}`}
    >
      <div className="bg-black text-center h-100 project">
        <div className="d-flex h-100">
          <div className="project-text w-100 my-auto text-center text-lg-right">
            <h4 className="text-white">{props.node.frontmatter.title}</h4>
            <p className="mb-0 text-white-50">
              {props.node.frontmatter.description}
            </p>
            <hr className="d-none d-lg-block mb-0 mr-0" />
          </div>
        </div>
      </div>
    </div>
  </Link>
);
