import React from 'react';

export default function Subscribe() {
  return (
    <section id="signup" className="signup-section">
      <div className="container">
        <div className="row">
          <div className="col-md-10 col-lg-8 mx-auto text-center">
            <i className="far fa-paper-plane fa-2x mb-2 text-white"></i>
            <h2 className="text-white mb-5">Send a message!</h2>

            <form name="contact" method="POST" className="form-inline" data-netlify="true">
              <input type="hidden" name="form-name" value="contact" />

              <div className="d-flex col-md-12 mb-2">
                <input
                  type="text"
                  name="name"
                  className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                  id="inputName"
                  placeholder="Enter your name..."
                />
                <input
                  type="email"
                  name="email"
                  className="form-control flex-fill mr-0 mr-sm-2 mb-3 mb-sm-0"
                  id="inputEmail"
                  placeholder="Enter email address..."
                />
              </div>
              <div className="d-flex col-md-12 mb-2">
                <textarea
                  name="message"
                  className="flex-fill form-control mr-0 mr-sm-2 mb-3 mb-sm-0"
                  id="inputMessage"
                  placeholder="Enter your message..."
                  rows="7"
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary mx-auto">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
